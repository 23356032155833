<template>
    <div class="login_page">
        <div class="center">
            <el-card class="box-card tc" v-loading="loading">
                <div slot="header" class="clearfix">
                    <h2>稿件初审平台</h2>
                </div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
                    <el-form-item label="用户名：" prop="username">
                        <el-input v-model="ruleForm.username" placeholder="请输入用户名"></el-input>
                    </el-form-item>
                    <!--@keypress 触发键盘事件 enter回车 native触发原生事件  -->
                    <el-form-item label="密码：" prop="password">
                        <el-input v-model="ruleForm.password" type="password" placeholder="请输入密码"></el-input>
                    </el-form-item>
                    <el-form-item label="验证码：" prop="captcha">
                        <el-row :gutter="0">
                            <el-col :span="16">
                                <el-input v-model="ruleForm.captcha" placeholder="请输入验证码" @keyup.enter.native="hadle">
                                    <!-- <a-icon slot="prefix" type="smile" :style="{ color: 'rgba(0,0,0,.25)' }" /> -->
                                </el-input>
                            </el-col>
                            <el-col :span="8" style="text-align: right;height: 40px">
                                <img v-if="requestCodeSuccess" :src="randCodeImage" @click="handleChangeCheckCode" />
                                <img v-else src="../assets/checkcode.png" @click="handleChangeCheckCode" />
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="login" @click="hadle">登录</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </div>
    </div>
</template>

<script>
import { get, login } from '../utils/http'
import { setToken } from '../utils/auth'
export default {
    data() {
        return {
            ruleForm: {
            },
            rules: {
                username: [
                    { required: true, message: "用户名不能为空", trigger: 'blur' },
                ],
                password: [
                    { required: true, message: '请输入密码!', validator: 'click' },
                ],
                captcha: [
                    { required: true, message: "验证码不能为空", trigger: 'blur' },
                ]
            },
            requestCodeSuccess: false,
            currdatetime: '',
            loading: false
        }
    },
    created() {
        this.handleChangeCheckCode()
    },
    methods: {
        hadle() {
            this.$refs["ruleForm"].validate((hadle) => {
                if (hadle) {
                    this.loading = true
                    this.ruleForm.checkKey = this.currdatetime
                    login("/sys/login", this.ruleForm).then((res) => {
                        setToken(res.result.token)
                        sessionStorage.setItem("nickname", res.result.userInfo.username)
                        this.$router.push('/')
                    }).catch((error) => {
                        if (error && error.code == 412) {
                            this.handleChangeCheckCode();
                        }
                        // this.$message.error(error.message)
                    }).finally(() => {
                        this.loading = false
                    })
                }
            })
        },
        handleChangeCheckCode() {
            this.currdatetime = new Date().getTime();
            delete this.ruleForm.checkCode;
            get(`/sys/randomImage/${this.currdatetime}`).then(res => {
                if (res.success) {
                    this.randCodeImage = res.result
                    this.$forceUpdate()
                    this.requestCodeSuccess = true
                } else {
                    this.$message.error(res.message)
                    this.requestCodeSuccess = false
                }
            }).catch(() => {
                this.requestCodeSuccess = false
            })
        },
    }
}
</script>

<style lang="less" scoped>
.login_page {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .center {
        width: 480px;
    }

    .kf_button {
        position: fixed;
        right: 10px;
        bottom: 200px;
        z-index: 100;
        background: #409EFF;
        width: 40px;
        height: 140px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        letter-spacing: 2px;
        color: #ffffff;
        writing-mode: vertical-rl;
        cursor: pointer;
        line-height: 20px;

        img {
            width: 30px;
            margin-top: 10px;
        }
    }

}


.login {
    width: 100%;
}

@media screen and(max-width: 759px) {
    .center {
        margin: 0 10px;
        width: 80%;
    }
}
</style>